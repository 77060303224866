.cta-text-block {
    @include brand-block();
    @include min-screen($screen__m) {
        display: flex;
    }
    &__content {
        @include min-screen($screen__l) {
            margin-right: $indent__base;
        }
        &__title {
            font-family: $h2__font-family;
            font-weight: $h2__font-weight;
            font-size: $h2__font-size;
            margin: 0;
            color: $brand__primary__color;
        }
        p {
            line-height: 1.4;
        }
    }
    &__icon {
        display: none;
        @include min-screen($screen__l) {
            display: flex;
            flex-basis: 50%;
            align-items: center;
            justify-content: center;
        }
        >span {
            font-size: 75px;
            color: $brand__secondary__color;
            @include min-screen($screen__l) {
                font-size: 100px;
            }
        }
    }
}
